import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'

import { SEOComponent } from '@/common/components/seo/seo'
import { HONG_HONG_SECTION, HONGKONG_SLUG } from '@/common/constants/gtmPageConstants'
import { HongKongContainer } from '@/components/containers/hongkong/hongKongLandingContainer'
import PreviewPage from '@/components/containers/previews/pagePreview'
import { env } from '@/env'
import { getClient } from '@/sanity/client'
import {
  getHongKongLandingQuery,
  hongKongLandingData,
} from '@/sanity/queries/hongkong/hongkongPage'
import {
  getQueryBasedOnStructure,
  mergeStructure,
} from '@/sanity/queries/page/pageCommonQueries/pageBuilderComponentsData'
import { getCommonData } from '@/sanity/services/common/getCommonData'
import {
  getHongKongLandingData,
  getHongKongStructure,
} from '@/sanity/services/hongkong/getHongKongLandingData'

type HongKongPageProps = InferGetStaticPropsType<typeof getStaticProps>

export default function HongKong(props: HongKongPageProps) {
  const { data, draftMode, token, layoutProps } = props

  if (draftMode && token) {
    return (
      <PreviewPage
        layoutProps={layoutProps}
        data={data}
        query={hongKongLandingData}
        seo={data.seo}
        Container={HongKongContainer}
        token={token}
      />
    )
  }

  return (
    <>
      <SEOComponent data={data.seo} />
      <HongKongContainer data={data} />
    </>
  )
}

export const getStaticProps = async (ctx: GetStaticPropsContext) => {
  const { draftMode = false, revalidateReason } = ctx
  const queryParams = { slug: HONGKONG_SLUG }
  const draftViewToken = draftMode ? env.SANITY_API_READ_TOKEN : ''
  const { client } = getClient({
    draftMode,
    draftViewToken,
    revalidateReason,
    slug: HONGKONG_SLUG,
    originName: 'hongkong-getStaticProps',
    filePath: __filename,
  })
  const dataStructure = await getHongKongStructure(client)
  const structureMerged = mergeStructure(dataStructure)
  const q = getQueryBasedOnStructure({ structure: structureMerged })
  const improvedQ = getHongKongLandingQuery(q)

  const [{ layoutProps, dataLayerProps }, data] = await Promise.all([
    getCommonData(client, queryParams),
    getHongKongLandingData(client, improvedQ),
  ])

  if (!data) return { notFound: true }
  if (dataLayerProps) dataLayerProps.page_data.site_section = HONG_HONG_SECTION

  return {
    props: {
      data,
      dataLayerProps,
      draftMode,
      token: draftViewToken,
      layoutProps,
    },
    revalidate: env.GLOBAL_REVALIDATE_VALUE,
  }
}
